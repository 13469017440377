<script>
import { mapGetters } from 'vuex';

import MobileDropdownControllerMixin from '@/mixins/MobileDropdownControllerMixin';

const types = {
  simple: defineAsyncComponent(() => import('./MobileSimpleDropdown')),
  complex: defineAsyncComponent(() => import('./MobileComplexDropdown')),
  ticketCategory: defineAsyncComponent(() =>
    import(
      '~/features/tickets/components/modals/mobile/MobileTicketCategoryDropdown'
    ),
  ),
  ticketMore: defineAsyncComponent(() =>
    import('~/features/tickets/components/modals/mobile/MobileTicketMoreOptions'),
  ),
  sortingDropdown: defineAsyncComponent(() =>
    import('./MobileSortingDropdown'),
  ),
  ticketsMobileFilteringDropdown: defineAsyncComponent(() =>
    import(
      '~/features/tickets/components/modals/mobile/MobileTicketFilteringDropdown'
    ),
  ),
  mobileFilteringMethodDropdown: defineAsyncComponent(() =>
    import('./FilteringSections/MobileFilteringMethod'),
  ),
};

export default defineNuxtComponent({
  name: 'MobileGenericDropdown',
  mixins: [MobileDropdownControllerMixin],
  props: {
    type: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    dropdownType() {
      return types[this.type];
    },
    ...mapGetters('MobileDropdownModalStore', [
      'dropdownOptions',
      'dropdownChecked',
      'dropdownLabel',
      'dropdownSecondaryChecked',
    ]),
  },
  methods: {
    closeDropdown(event) {
      if (event.target.id === 'dropdownsShadow')
        this.setDropdownOpened(false);
    },
  },
});
</script>

<template>
  <div
    id="dropdownsShadow"
    class="fixed left-0 top-0 z-105 h-full w-full bg-neutral-1-500 bg-opacity-50"
    @click="closeDropdown"
  >
    <div
      class="absolute bottom-0 w-full rounded bg-white p-4 dark:bg-dark-secondary"
    >
      <component
        :is="dropdownType"
        :checked="dropdownChecked"
        :label="dropdownLabel"
        :options="dropdownOptions"
      />
    </div>
  </div>
</template>
