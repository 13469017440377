<script>
import { mapState } from 'vuex';

import ColorUtilities from '~/utilities/ColorUtilities';

import Icon from '@/components/atoms/Icon';

export default defineNuxtComponent({
  name: 'MobileTopBar',
  components: {
    Icon,
  },
  props: {
    sidebarCollapsed: {
      type: Boolean,
      default: true,
      required: true,
    },
    secondarySidebarCollapsed: {
      type: Boolean,
      default: true,
      required: true,
    },
    activeItemTitle: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    ...mapState('ContextStore', {
      color: state => state.accentColor,
      darkMode: state => state.darkMode,
    }),
    whiteColor() {
      return ColorUtilities.WHITE;
    },
  },
  methods: {
    onMenuClick() {
      this.$emit('onMenuItemClick');
    },
    onArrowLeftClick() {
      this.$emit('onArrowLeftClick');
    },
    onClose() {
      this.$emit('onClose');
    },
    onCreate() {
      this.$emit('onCreate');
    },
  },
});
</script>

<template>
  <div
    class="fixed flex h-20 w-full items-center bg-secondary p-7 dark:bg-dark-secondary"
  >
    <div class="flex items-center">
      <div @click="onMenuClick">
        <trailblazer-icon
          name="menu"
          :color="whiteColor"
          class="block overflow-hidden transition-opacity duration-200"
          :class="{
            'w-0': !sidebarCollapsed || !secondarySidebarCollapsed,
          }"
        />
      </div>
      <div @click="onArrowLeftClick">
        <trailblazer-icon
          name="arrow-left"
          :color="whiteColor"
          class="block transition-all duration-200"
          :class="{
            'w-0 overflow-hidden':
              !sidebarCollapsed || secondarySidebarCollapsed,
          }"
        />
      </div>
    </div>

    <div
      class="flex flex-1 items-center transition-all duration-200"
      :class="{
        'margin-0': !sidebarCollapsed,
      }"
    >
      <div
        v-if="sidebarCollapsed && !secondarySidebarCollapsed && activeItemTitle"
        class="text-2xl text-white"
      >
        {{ __(activeItemTitle) }}
      </div>
      <LogoBig v-else class="centered-margin w-40 text-white" />
    </div>

    <div class="ml-auto flex items-center">
      <div
        v-if="sidebarCollapsed && secondarySidebarCollapsed"
        @click="onCreate"
      >
        <trailblazer-icon
          class="cursor-pointer transition-opacity duration-200"
          :color="whiteColor"
          :class="{
            'animate-rotateToRight360':
              sidebarCollapsed && secondarySidebarCollapsed,
          }"
          name="add-outline"
        />
      </div>
      <div v-else @click="onClose">
        <trailblazer-icon
          class="cursor-pointer transition-all duration-200"
          :color="whiteColor"
          :class="{
            'animate-rotateToLeft360':
              !sidebarCollapsed || !secondarySidebarCollapsed,
          }"
          name="close"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.centered-margin {
  margin-left: calc(50% - 80px);
}
</style>
